import { useTranslations } from "next-intl";

const Facilidades = ({
  query,
  setQuery,
}: {
  query: URLSearchParams;
  setQuery: any;
}) => {
  const t = useTranslations(
    "home.filterWithProperty.advanceFilterModal.fields.facilities.items"
  );

  const amenities = [
    [
      { value: "pool", label: t("pool"), defaultChecked: false },
      { value: "sport_court", label: t("sport_court"), defaultChecked: false },
      { value: "gym", label: t("gym"), defaultChecked: false },
      { value: "sauna", label: t("sauna"), defaultChecked: false },
    ],
    [
      { value: "spa", label: t("spa"), defaultChecked: false },
      { value: "toy_library", label: t("toy_library"), defaultChecked: false },
      {
        value: "tennis_court",
        label: t("tennis_court"),
        defaultChecked: false,
      },
      { value: "balcony", label: t("balcony"), defaultChecked: false },
    ],
    // [
    //   { label: "Lake view" },
    //   { label: "Wine cellar" },
    //   { label: "Front yard" },
    //   { label: "Refrigerator" },
    // ],
  ];

  return (
    <>
      {amenities.map((column, columnIndex) => (
        <div className="col-sm-4" key={columnIndex}>
          <div className="widget-wrapper mb20">
            <div className="checkbox-style1">
              {column.map((amenity, amenityIndex) => (
                <label className="custom_checkbox" key={amenityIndex}>
                  {amenity.label}
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      setQuery(
                        new URLSearchParams({
                          ...Object.fromEntries(query),
                          amenities: [
                            ...(query?.get("amenities") || "")?.split(","),
                            !query?.get("amenities")?.includes(amenity.value)
                              ? amenity.value
                              : "",
                          ].join(),
                        })
                      );
                    }}
                    defaultChecked={amenity.defaultChecked}
                  />
                  <span className="checkmark" />
                </label>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Facilidades;
