"use client";
import { useState } from "react";
import dynamic from "next/dynamic";

const Select = dynamic(() => import("react-select"), { ssr: false });
import PriceRange from "./PriceRange";
import Suites from "./Suites";
import Spaces from "./Spaces";
import Facilidades from "./Amenities";
import { useRouter } from "next/navigation";
import { useTranslations } from "next-intl";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  neighborhoods: string[];
}

const AdvanceFilterModal = (props: Props) => {
  const t = useTranslations("home.filterWithProperty.advanceFilterModal");

  const router = useRouter();
  const catOptions = [
    { value: "all", label: t("fields.type.items.all") },
    { value: "apartments", label: t("fields.type.items.apartments") },
    { value: "houses", label: t("fields.type.items.houses") },
    {
      value: "condominium_houses",
      label: t("fields.type.items.condominium_houses"),
    },
    { value: "roof", label: t("fields.type.items.roof") },
    { value: "commercial", label: t("fields.type.items.commercial") },
    { value: "ground", label: t("fields.type.items.ground") },
  ];
  const locationOptions = [
    { value: "all", label: t("fields.location.items.allNeighborhoods") },
    { value: "Jardim Europa", label: "Jardim Europa" },
    { value: "Itaim Bibi", label: "Itaim Bibi" },
    { value: "Vila Nova Conceição", label: "Vila Nova Conceição" },
    { value: "Cidade Jardim", label: "Cidade Jardim" },
    { value: "Jardim América", label: "Jardim América" },
    { value: "Jardim Paulista", label: "Jardim Paulista" },
    { value: "Jardim Paulistano", label: "Jardim Paulistano" },
    { value: "Alto de Pinheiros", label: "Alto de Pinheiros" },
    { value: "Campo Belo", label: "Campo Belo" },
    { value: "Brooklin", label: "Brooklin" },
    ...(props.neighborhoods
      ?.sort((a, b) => (a < b ? -1 : a === b ? 0 : 1))
      .map((n) => ({ value: n, label: n })) || []),
  ];

  const [query, setQuery] = useState<URLSearchParams>(new URLSearchParams());

  const customStyles = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? "var(--primary)"
          : isHovered
            ? "var(--primary)12"
            : isFocused
              ? "var(--primary)12"
              : undefined,
      };
    },
  };

  function submit() {
    router.push(`/fast?${query.toString()}`);
  }

  function gotoProperty() {
    router.push(`/imovel/${query.get("ref")}`);
  }

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header pl30 pr30">
          <h5 className="modal-title font-bold text-xl" id="exampleModalLabel">
            {t("title")}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          />
        </div>
        {/* End modal-header */}

        <div className="modal-body pb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="widget-wrapper">
                <p className="list-title mb20">
                  {t("fields.priceRange.label")}
                </p>
                <div className="range-slider-style modal-version">
                  <PriceRange query={query} />
                </div>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div className="row">
            <div className="col-sm-6">
              <div className="widget-wrapper">
                <p className="list-title">{t("fields.type.label")}</p>
                <div className="form-style2 input-group">
                  <Select
                    // defaultValue={[catOptions[1]]}
                    name="colors"
                    options={catOptions}
                    isMulti
                    //@ts-ignore
                    styles={customStyles}
                    className="select-custom"
                    classNamePrefix="select"
                    placeholder={t("fields.type.placeholder")}
                    onChange={(e: any) => {
                      query.set(
                        "property_type",
                        e.map((e) => e.value).join(",")
                      );
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            {/* End .col-6 */}

            <div className="col-sm-6">
              <div className="widget-wrapper">
                <p className="list-title">{t("fields.propertyRef.label")}</p>
                <div className="form-style2 flex items-center gap-1">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        if (e.target.value.startsWith("MB")) {
                          query.set("ref", e.target.value);
                        } else {
                          query.set("ref", `MB${e.target.value}`);
                        }
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        gotoProperty();
                        e.currentTarget.setAttribute(
                          "data-bs-dismiss",
                          "modal"
                        );
                        e.currentTarget.click();
                      }
                    }}
                    placeholder="MB11111"
                  />
                  <button
                    className="ml-2 ud-btn btn-apple w-10 h-12 right-0 top-0 p-0 flex items-center justify-center"
                    style={{
                      background: "#21293A",
                    }}
                    onClick={(e) => {
                      gotoProperty();
                      e.currentTarget.setAttribute("data-bs-dismiss", "modal");
                      e.currentTarget.click();
                    }}
                  >
                    <IoIosArrowForward className="w-full" />
                  </button>
                </div>
              </div>
            </div>
            {/* End .col-6 */}
          </div>
          {/* End .row */}

          <div className="row">
            <div className="col-sm-6">
              <div className="widget-wrapper">
                <p className="list-title">{t("fields.suites.label")}</p>
                <div className="d-flex">
                  <Suites query={query} />
                </div>
              </div>
            </div>
            {/* End .col-md-6 */}

            <div className="col-sm-6">
              <div className="widget-wrapper">
                <p className="list-title">{t("fields.spaces.label")}</p>
                <div className="d-flex">
                  <Spaces query={query} />
                </div>
              </div>
            </div>
            {/* End .col-md-6 */}
          </div>
          {/* End .row */}

          <div className="row">
            <div className="col-sm-6">
              <div className="widget-wrapper">
                <p className="list-title">{t("fields.location.label")}</p>
                <div className="form-style2 input-group">
                  <Select
                    // defaultValue={[locationOptions[0]]}
                    isMulti
                    name="colors"
                    //@ts-ignore
                    styles={customStyles}
                    options={locationOptions}
                    className="select-custom"
                    classNamePrefix="select"
                    placeholder={t("fields.type.placeholder")}
                    onChange={(e) => {
                      query.set(
                        "location",
                        //@ts-ignore
                        e.map((e) => e.value).join(",")
                      );
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            {/* End .col-md-6 */}

            <div className="col-sm-6">
              <div className="widget-wrapper">
                <p className="list-title">{t("fields.footage.label")}</p>
                <div className="space-area">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="form-style1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Min"
                        onChange={(e) => {
                          query.set("min_area", e.target.value);
                        }}
                      />
                    </div>
                    <span className="dark-color">-</span>
                    <div className="form-style1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Max"
                        onChange={(e) => {
                          query.set("max_area", e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End .col-md-6 */}
          </div>
          {/* End .row */}

          <div className="row">
            <div className="col-lg-12">
              <div className="widget-wrapper mb0">
                <p className="list-title mb10">
                  {t("fields.facilities.label")}
                </p>
              </div>
            </div>
            <Facilidades query={query} setQuery={setQuery} />
          </div>
        </div>
        {/* End modal body */}

        <div className="modal-footer justify-content-between">
          <button className="reset-button">
            {/* <span className="flaticon-turn-back" />
            <u>{t("buttons.cleanAllFilters")}</u> */}
          </button>
          <div className="btn-area">
            <button
              data-bs-dismiss="modal"
              type="submit"
              className={`ud-btn btn-thm bg-[#21293A] hover:bg-[#4D7079]`}
              onClick={submit}
            >
              <span className="flaticon-search align-text-top pr10" />
              {t("buttons.search")}
            </button>
          </div>
        </div>
        {/* End modal-footer */}
      </div>
    </div>
  );
};

export default AdvanceFilterModal;
