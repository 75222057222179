"use client";
import React from "react";
import "swiper/css";
import { useTranslations } from "next-intl";

const Hero = () => {
  const t = useTranslations("home.hero");

  return (
    <div>
      <div className="hero-large-home5 relative h-[75dvh] md:h-[85dvh]">
        {typeof window !== "undefined" && window.innerWidth < 768 ? (
          // <CldVideoPlayer
          //   width={window.innerWidth}
          //   height={window.innerWidth}
          //   autoPlay
          //   autoplay
          //   muted
          //   quality={1}
          //   src="site/jcq9ehyurxyonjyjq2wm"
          //   controls={false}
          // />
          <video
            autoPlay
            playsInline
            preload="metadata"
            muted
            loop
            className="w-full h-full object-cover"
            src="https://res.cloudinary.com/mbras/video/upload/q_auto,w_auto,dpr_auto/v1721862124/site/jcq9ehyurxyonjyjq2wm.mp4"
          />
        ) : (
          <video
            autoPlay
            playsInline
            preload="metadata"
            muted
            loop
            className="w-full h-full object-cover"
            src="https://res.cloudinary.com/mbras/video/upload/q_auto,w_auto,dpr_auto/v1721862124/mbras-desktop_a4bqen.mp4"
          />
        )}

        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            fontWeight: "bold",
            background: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1
            className="text-white"
            style={{
              fontFamily: "Montserrat",
              fontWeight: 200,
              fontSize: "3rem",
              padding: "1rem",
            }}
          >
            Referência em Altíssimo Padrão
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Hero;
